@import "https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap";
@import "https://fonts.googleapis.com/css2?family=El+Messiri:wght@400&display=swap";
h1 {
  margin: 10px 0;
  font-family: El Messiri, sans-serif;
  font-size: clamp(33px, 3.0719vw + 21.0196px, 80px);
  font-weight: 400;
}

@media (width >= 1024px) {
  h1 {
    margin: 20px 0;
  }
}

h2 {
  margin: 10px 0;
  font-family: El Messiri, sans-serif;
  font-size: clamp(26px, 2.22222vw + 17.3333px, 60px);
  font-weight: 400;
}

@media (width >= 1024px) {
  h2 {
    margin: 20px 0;
  }
}

h3 {
  margin: 10px 0;
  font-family: El Messiri, sans-serif;
  font-size: clamp(22px, 1.30719vw + 16.902px, 42px);
}

@media (width >= 1024px) {
  h3 {
    margin: 20px 0;
  }
}

h4 {
  margin: 10px 0;
  font-size: clamp(19px, .718954vw + 16.1961px, 30px);
  font-weight: 300;
}

@media (width >= 1024px) {
  h4 {
    margin: 20px 0;
  }
}

@media (width >= 1400px) {
  h4 {
    margin: 20px 0;
  }
}

.muted {
  opacity: .6;
}

.small {
  font-size: clamp(14px, .261438vw + 12.9804px, 18px);
}

.xsmall {
  font-size: clamp(12px, .130719vw + 11.4902px, 14px);
}

.nomargin {
  margin: 0;
}

body {
  color: #1b201b;
  letter-spacing: -.3px;
  scroll-behavior: smooth;
  background-color: #fff;
  margin: auto;
  font-family: Work Sans, sans-serif;
  font-size: clamp(17px, .522876vw + 14.9608px, 25px);
  font-weight: 400;
  line-height: 1.4;
  transition: all .3s cubic-bezier(.55, .09, .68, .53);
  overflow-x: hidden;
}

body a {
  color: #1b201b;
}

body button {
  font-family: Work Sans, sans-serif;
  font-weight: 400;
}

body img {
  max-width: 100%;
  margin: auto;
}

body .privacy_link a {
  color: #1b201b;
}

body .nospace {
  margin: 0;
  padding: 0;
}

body .nospace.bottom {
  margin-bottom: inherit;
  padding-bottom: inherit;
}

body.dark {
  color: #fff;
  background-color: #1b201b;
}

body.dark a, body.dark .ckb-navigation a {
  color: #fff;
}

body.dark .ckb-navigation a:before {
  color: #fff !important;
}

body.dark .hero-text-wrapper {
  color: #fff;
}

body.dark .m-button {
  color: #1b201b;
}

body.dark .m-button:hover {
  color: #fff;
}

body.dark .top-gradient {
  background: linear-gradient(#1b201b, #1b201b00);
}

.top-gradient {
  opacity: 1;
  z-index: 1;
  background: linear-gradient(#edf1d6 0%, #0f282700 100%);
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

@media (width >= 850px) {
  .top-gradient {
    height: 80px;
  }
}

@media (width >= 1024px) {
  .top-gradient {
    height: 120px;
  }
}

.hero-wrapper {
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 390px;
  padding: 24px 16px 50px;
  display: flex;
}

@media (width >= 1024px) {
  .hero-wrapper {
    justify-content: left;
    padding: 0 4vw;
  }
}

.hero-wrapper .arrow {
  opacity: 0;
  width: 22px;
  margin: 25px auto auto;
  animation: 1s 2.2s forwards fadeIn;
  display: flex;
}

@media (width >= 1024px) {
  .hero-wrapper .arrow {
    display: none;
  }
}

.hero-wrapper .hero-text {
  margin: 0;
}

@media (width >= 1024px) {
  .hero-wrapper .hero-text {
    margin: 120px auto 0;
  }
}

#info-section-wrapper {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1920px;
  margin: 0 auto 15vh;
  padding: 0 16px;
  display: flex;
}

@media (width >= 850px) {
  #info-section-wrapper {
    margin: 20vh auto;
  }
}

@media (width >= 1024px) {
  #info-section-wrapper {
    padding: 0 8vw;
  }
}

#info-section-wrapper .img-hero {
  opacity: 0;
  width: 100%;
  position: relative;
}

@media (width >= 850px) {
  #info-section-wrapper .img-hero {
    width: 45%;
  }
}

#info-section-wrapper .img-hero .ih-wrapper {
  text-align: center;
}

@media (width >= 850px) {
  #info-section-wrapper .img-hero .ih-wrapper {
    max-width: 660px;
  }
}

#info-section-wrapper .img-hero img {
  object-fit: cover;
  border-radius: 8px 150px 8px 8px;
  max-height: 485px;
}

@media (width >= 850px) {
  #info-section-wrapper .img-hero img {
    width: 100%;
    max-height: 90vh;
  }
}

#info-section-wrapper .img-hero .namespin-wrapper img {
  border-radius: 0;
}

#info-section-wrapper .ckb-about-text {
  width: 100%;
  height: fit-content;
  padding: 24px 0 0;
}

@media (width >= 850px) {
  #info-section-wrapper .ckb-about-text {
    width: 50%;
    padding: 0 0 0 5%;
  }
}

#info-section-wrapper .ckb-about-text .ca-text {
  opacity: 0;
  text-align: center;
  transition: all .3s;
  animation-delay: .4s;
}

@media (width >= 850px) {
  #info-section-wrapper .ckb-about-text .ca-text {
    text-align: left;
  }
}

#info-section-wrapper .ckb-about-text .ca-text p {
  margin: 24px auto;
}

@media (width >= 1400px) {
  #info-section-wrapper .ckb-about-text .ca-text p {
    margin: 32px auto;
  }
}

#info-section-wrapper .ckb-about-text .ca-text .ckb-topic-marker {
  margin: auto;
}

@media (width >= 850px) {
  #info-section-wrapper .ckb-about-text .ca-text .ckb-topic-marker {
    margin: 0 auto 0 0;
  }
}

.namespin-wrapper {
  opacity: .5;
  width: 60px;
  height: 60px;
  animation: 6s linear infinite spin;
  display: none;
  position: absolute;
  top: -30px;
  left: -30px;
}

@media (width >= 850px) {
  .namespin-wrapper {
    width: 70px;
    height: 70px;
    animation: 4s linear infinite spin;
    display: block;
    top: -30px;
    left: -10px;
  }
}

@media (width >= 1024px) {
  .namespin-wrapper {
    width: 90px;
    height: 90px;
    top: -30px;
    left: -30px;
  }
}

.morejobs {
  max-width: 720px;
  margin: 8vh auto;
  padding: 0 16px;
  transition: all .3s;
}

@media (width >= 1024px) {
  .morejobs {
    margin: 8vh auto 5vh;
    padding: 0 8vw;
  }
}

.morejobs #showpanel {
  margin-bottom: 60px;
  display: none;
}

.clients-highlight {
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 1920px;
  margin: 15vh auto;
  padding: 0 16px;
  transition: all .3s;
  display: flex;
}

@media (width >= 850px) {
  .clients-highlight {
    margin: 20vh auto;
  }
}

@media (width >= 1024px) {
  .clients-highlight {
    padding: 0 8vw;
  }
}

.clients-highlight .client-logo {
  opacity: 0;
  align-self: center;
  width: 26%;
  margin: 6% 12%;
}

@media (width >= 850px) {
  .clients-highlight .client-logo {
    width: 10%;
    margin: 3% 5%;
  }
}

@media (width >= 1400px) {
  .clients-highlight .client-logo {
    width: 8%;
    margin: 3% 6%;
  }
}

.clients-highlight .small {
  max-width: 90px;
  display: flex;
}

@media (width >= 1024px) {
  .clients-highlight .small {
    max-width: 100%;
  }
}

.clients-highlight .msmall {
  max-width: 90px;
  display: flex;
}

@media (width >= 1024px) {
  .clients-highlight .msmall {
    max-width: 140px;
  }
}

.clients-highlight .xsmall {
  max-width: 70px;
  display: flex;
}

@media (width >= 1024px) {
  .clients-highlight .xsmall {
    max-width: 80px;
  }
}

.ml16, .ml16-fp-1, .ml16-fp-2, .ml16-fp-3 {
  opacity: 0;
  animation: 1s .1s forwards fadeIn;
  overflow: hidden;
}

.ml16 .letter, .ml16-fp-1 .letter, .ml16-fp-2 .letter, .ml16-fp-3 .letter {
  line-height: .5em;
  display: inline-block;
}

.ml16-p-1, .ml16-p-2 {
  opacity: 0;
  animation: 2s .3s forwards fadeIn;
  overflow: hidden;
}

.ml16-p-1 .letter, .ml16-p-2 .letter {
  line-height: 1em;
  display: inline-block;
}

h1.ml16-fp-1, h1.ml16-fp-2, h1.ml16-fp-3 {
  text-align: center;
  opacity: 0;
  margin-top: 0;
  margin-bottom: 0;
  animation: 2s .3s forwards fadeIn;
}

.ml16-p-2 {
  margin-top: 0;
  margin-bottom: 0;
}

@media (width >= 1024px) {
  a, button {
    cursor: none;
  }
}

.cursor-dot, .cursor-dot-outline {
  pointer-events: none;
  opacity: 0;
  z-index: 99999;
  border-radius: 50%;
  transition: opacity .3s ease-in-out, transform .3s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (width >= 1024px) {
  .cursor-dot {
    background-color: #5b6f51;
    width: 8px;
    height: 8px;
  }

  .cursor-dot-outline {
    background-color: #5b6f5180;
    width: 40px;
    height: 40px;
  }
}

a.l1, button.l1 {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  color: #1b201b;
  padding-bottom: 3px;
  position: relative;
}

a.l2, button.l2 {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  position: relative;
}

a.l1:before, a.l2:before, a.l1:before, button.l1:before, button.l2:before, button.l1:before {
  content: "";
  transform-origin: 0 100%;
  background-color: #5b6f51;
  height: 2px;
  transition: transform .3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

a.l1:hover:before, a.l2:hover:before, a.l1:hover:before, button.l1:hover:before, button.l2:hover:before, button.l1:hover:before {
  transform-origin: 100% 100%;
  transform: scaleX(0);
}

.job-link-wrapper {
  max-width: 1920px;
  margin: auto;
  padding: 0 16px 20px;
}

@media (width >= 1024px) {
  .job-link-wrapper {
    padding: 0 8vw 40px;
  }
}

.job-link-wrapper a {
  color: #fff;
}

.job-link-wrapper a:first-child {
  margin-right: 10px;
}

.job-link-wrapper a.current {
  color: #5b6f51;
  text-decoration: none;
}

.job-link-wrapper .markers {
  opacity: 0;
  margin-top: 10px;
}

.ckb-topic-marker, .ckb-topic-marker-two {
  color: #fff;
  width: 80px;
  overflow: hidden;
}

.ckb-topic-marker.dark, .ckb-topic-marker-two.dark {
  color: #1b201b;
}

.ckb-topic-marker.ckb-topic-marker, .ckb-topic-marker-two.ckb-topic-marker {
  opacity: .4;
}

.ckb-topic-marker .content, .ckb-topic-marker-two .content {
  will-change: transform;
  font-size: clamp(12px, .130719vw + 11.4902px, 14px);
  animation: 20s linear infinite roll;
}

@keyframes roll {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-500px);
  }

  100% {
    transform: translateX(0);
  }
}

.selection-list {
  flex-flow: column wrap;
  max-width: 1600px;
  margin: auto;
  padding: 0 16px;
  transition: all .3s;
  display: flex;
}

@media (width >= 1024px) {
  .selection-list {
    flex-direction: row;
    padding: 0 4vw;
  }
}

.selection-list .selection-list-item {
  width: 100%;
  margin: 0 0 5vh;
}

.selection-list .selection-list-item video {
  border-radius: 8px;
  max-width: 100%;
  height: auto;
}

.selection-list .selection-list-item img {
  border-radius: 8px;
}

@media (width >= 1024px) {
  .selection-list .selection-list-item {
    width: 48%;
    margin: 0 0 10vh;
  }

  .selection-list .selection-list-item.first {
    margin: 0 4% 0 0;
  }
}

.selection-list .selection-list-item.full {
  width: 100%;
}

.j1intro_jobb {
  text-align: center;
  max-width: 100%;
  margin-bottom: 0;
  padding-top: 19.5vh;
  padding-bottom: 15vh;
}

@media (width >= 1024px) {
  .j1intro_jobb {
    text-align: left;
    max-width: 80%;
  }
}

.j1intro_jobb h1 {
  hyphens: auto;
  opacity: 0;
  color: #1b201b;
  margin: 0 0 20px;
  line-height: 1.7;
  animation: 2s .1s forwards fadeIn;
}

.j1intro_jobb h4 {
  opacity: 0;
  animation: 1s .5s forwards RevealThings;
}

.job-single-list {
  justify-content: center;
  max-width: 1600px;
  margin: auto;
  padding: 0 20px;
  transition: all .3s;
  display: flex;
}

@media (width >= 1024px) {
  .job-single-list {
    padding: 0 4vw;
  }
}

.job-single-list video {
  border-radius: 8px;
  max-width: 100%;
  height: auto;
}

.job-single-list .jsingle {
  opacity: 0;
  animation: 1s .5s forwards RevealThings;
}

.job-single-list .jsingle img {
  border-radius: 8px;
}

.job-single-list .jsingle .jsingle_tekst {
  text-align: left;
  max-width: 700px;
  margin: auto;
  padding: 40px 0;
}

@media (width >= 544px) {
  .job-single-list .jsingle .jsingle_tekst {
    max-width: 70vw;
  }
}

@media (width >= 1024px) {
  .job-single-list .jsingle .jsingle_tekst {
    padding: 80px 0;
    font-size: clamp(17px, .522876vw + 14.9608px, 25px);
  }
}

@media (width >= 1400px) {
  .job-single-list .jsingle .jsingle_tekst {
    max-width: 50vw;
  }
}

@media (width >= 1920px) {
  .job-single-list .jsingle .jsingle_tekst {
    max-width: 40vw;
  }
}

@media (width >= 2200px) {
  .job-single-list .jsingle .jsingle_tekst {
    max-width: 1000px;
  }
}

.job-single-list .jsingle .jsingle_tekst .nomargintop {
  margin-top: 0;
}

.job-single-list .jsingle .fiftyfifty-img {
  display: flex;
}

.job-single-list .jsingle .fiftyfifty-img img {
  width: 49%;
  height: 100%;
}

.job-single-list .jsingle .fiftyfifty-img img:first-child {
  margin-right: 2%;
}

.job-single-list .jsingle.center {
  text-align: center;
}

.privacy_link {
  text-align: center;
  color: #1b201b;
  opacity: .8;
  padding-bottom: 10px;
  font-size: clamp(12px, .130719vw + 11.4902px, 14px);
}

.privacy_link.dark {
  color: #fff;
}

.desktop {
  display: none;
}

@media (width >= 850px) {
  .desktop {
    display: block;
  }
}

.mobile {
  display: block;
}

@media (width >= 850px) {
  .mobile {
    display: none;
  }
}

.show {
  opacity: 1;
  pointer-events: all;
}

.hide {
  opacity: 0;
  pointer-events: none;
}

.reveal {
  opacity: 0;
  animation: 1s forwards RevealThings;
}

.reveal-slight {
  opacity: 0;
  animation: 1s forwards RevealThingsSlight;
}

.reveal-fx {
  opacity: 0;
  animation: 1s forwards reveal-fx;
}

.fiftyfifty-ref-wrapper {
  flex-wrap: wrap;
  max-width: 1920px;
  margin: auto;
  padding: 0 16px;
  font-size: clamp(12px, .130719vw + 11.4902px, 14px);
  display: flex;
}

@media (width >= 1024px) {
  .fiftyfifty-ref-wrapper {
    padding: 0 8vw;
  }
}

.fiftyfifty-ref-wrapper .item {
  width: 100%;
}

@media (width >= 1024px) {
  .fiftyfifty-ref-wrapper .item {
    width: 45%;
  }
}

.fiftyfifty-ref-wrapper .item:nth-child(odd) {
  padding: 0 0 10vh;
}

@media (width >= 1024px) {
  .fiftyfifty-ref-wrapper .item:nth-child(odd) {
    padding: 0 5% 0 0;
  }
}

.fiftyfifty-ref-wrapper .item:nth-child(2n) {
  padding: 0 0 10vh;
}

@media (width >= 1024px) {
  .fiftyfifty-ref-wrapper .item:nth-child(2n) {
    padding: 0 0 0 5%;
  }
}

.fiftyfifty-ref-wrapper .item:last-child {
  padding: 0;
}

@media (width >= 1024px) {
  .fiftyfifty-ref-wrapper .item:last-child {
    padding: 0 0 0 5%;
  }
}

.fiftyfifty-ref-wrapper .item .job-element-single {
  text-decoration: none;
}

.fiftyfifty-ref-wrapper .item .job-element-single .job-element-image {
  line-height: 0;
}

.fiftyfifty-ref-wrapper .item .job-element-single .job-element-image img {
  border-radius: 8px;
  transition: all .3s;
}

.fiftyfifty-ref-wrapper .item .job-element-single .job-element-image video {
  border-radius: 8px;
  max-width: 100%;
  height: auto;
  transition: all .3s;
}

.fiftyfifty-ref-wrapper .item .job-element-single .job-element-text {
  opacity: .8;
  font-size: clamp(17px, .522876vw + 14.9608px, 25px);
  transition: all .5s;
}

.fiftyfifty-ref-wrapper .item .job-element-single .job-element-text .info {
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  display: flex;
}

.fiftyfifty-ref-wrapper .item .job-element-single .job-element-text .info .pill {
  color: #f9eda8;
  border: 1px solid #f9eda8;
  border-radius: 100px;
  height: fit-content;
  padding: 4px 12px;
  font-size: clamp(14px, .261438vw + 12.9804px, 18px);
  transition: all .5s;
}

@media (width >= 1024px) {
  .fiftyfifty-ref-wrapper .item .job-element-single:hover .job-element-image img, .fiftyfifty-ref-wrapper .item .job-element-single:hover .job-element-image video {
    transform: scale(1.01);
  }
}

.fiftyfifty-ref-wrapper .item .job-element-single:hover .job-element-text {
  opacity: 1;
}

@media (width >= 1024px) {
  .fiftyfifty-ref-wrapper .item .job-element-single:hover .job-element-text {
    transform: translate(-3px, 3px) !important;
  }

  .fiftyfifty-ref-wrapper .item .job-element-single:hover .job-element-text .pill {
    transform: rotate(-5deg) !important;
  }

  .fiftyfifty-ref-wrapper .second {
    padding-top: 60px;
  }

  .fiftyfifty-ref-wrapper .third, .fiftyfifty-ref-wrapper .fifth {
    margin-top: 12vh;
  }
}

.secondary-ref-wrapper {
  grid-gap: 140px;
  grid-template-columns: 1fr;
  max-width: 1920px;
  margin: 12vh auto 0;
  padding: 0 16px;
  display: grid;
}

@media (width >= 1024px) {
  .secondary-ref-wrapper {
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 8vw;
  }
}

@media (width >= 1400px) {
  .secondary-ref-wrapper {
    grid-gap: 60px;
  }
}

.secondary-ref-wrapper img {
  border-radius: 8px;
}

.secondary-ref-wrapper a {
  text-decoration: none;
}

@media (width >= 1024px) {
  .secondary-ref-wrapper a p {
    margin-top: 12px;
  }
}

.secondary-ref-wrapper a .job-element-image img {
  transition: all .3s;
}

.secondary-ref-wrapper a .job-element-text {
  transition: all .5s;
}

.secondary-ref-wrapper a:hover .job-element-image img {
  opacity: 1;
}

@media (width >= 1024px) {
  .secondary-ref-wrapper a:hover .job-element-image img {
    transform: scale(1.01);
  }

  .secondary-ref-wrapper a:hover .job-element-text {
    transform: translate(-2.5px, 2.5px) !important;
  }
}

.sublead {
  text-align: center;
  opacity: 0;
  color: #f9eda8;
  padding-top: 12px;
  animation: 1s 1.6s forwards fadeIn;
}

@media (width >= 1024px) {
  .sublead {
    padding-top: 16px;
  }
}

@media (width >= 1920px) {
  .sublead {
    padding-top: 32px;
  }
}

#cases {
  margin: auto;
}

#cases .case-element {
  border-bottom: 1px solid #9dc08b;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  font-size: clamp(14px, .261438vw + 12.9804px, 18px);
  text-decoration: none;
  transition: all .3s;
  display: flex;
}

#cases .case-element:first-child {
  padding-top: 0;
}

#cases .case-element .description {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

@media (width >= 850px) {
  #cases .case-element .description {
    width: 100%;
  }
}

#cases .case-element .description .title {
  width: 100%;
  margin-bottom: 4px;
  font-weight: 500;
}

@media (width >= 850px) {
  #cases .case-element .description .title {
    width: 45%;
    margin-bottom: 0;
    margin-right: 16px;
  }
}

#cases .case-element .description .type {
  color: #f9eda8;
  width: 100%;
}

@media (width >= 850px) {
  #cases .case-element .description .type {
    width: auto;
  }
}

#cases .case-element .more {
  justify-content: flex-end;
  align-items: center;
  width: 25%;
  display: flex;
}

#cases .case-element svg {
  width: 19px;
}

@media (width >= 1024px) {
  #cases .case-element svg {
    width: 24px;
  }
}

#cases .case-element:last-child {
  margin-bottom: 0;
}

#cases .case-element p, #cases .case-element img {
  margin: 0;
}

.se-mer {
  opacity: 0;
  max-width: 1920px;
  transform: .3s all;
  margin: 15vh auto;
  padding: 0 16px;
  transition: all .3s;
}

@media (width >= 850px) {
  .se-mer {
    margin: 20vh auto;
  }
}

@media (width >= 1024px) {
  .se-mer {
    padding: 0 8vw;
  }
}

.se-mer .se-content {
  color: #1b201b;
  opacity: 0;
  background: #fff;
  border-radius: 8px;
  flex-direction: column;
  padding: 80px 24px;
  animation: 1s forwards RevealThings;
  display: flex;
}

@media (width >= 850px) {
  .se-mer .se-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 140px 80px;
  }
}

.se-mer .se-content .heading {
  margin-bottom: 40px;
}

@media (width >= 850px) {
  .se-mer .se-content .heading {
    margin-bottom: 0;
  }
}

.se-mer .se-content .heading h2 {
  margin: 0;
}

.se-mer .se-content .links h4 {
  margin-bottom: 24px;
}

.se-mer .se-content .links h4:last-child {
  margin-bottom: 0;
}

.se-mer .se-content .links a.l1, .se-mer .se-content .links button.l1 {
  color: #1b201b;
}

.m-button {
  color: #1b201b;
  cursor: pointer;
  background-color: #9dc08b;
  border: 1px solid #9dc08b;
  border-radius: 50px;
  outline: none;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 44px;
  margin: auto;
  padding: 0 16px;
  text-decoration: none;
  transition: transform .25s linear, color .1s linear, background .15s linear;
  display: flex;
  position: relative;
}

@media (width >= 850px) {
  .m-button {
    height: 48px;
    margin: 0 auto 0 0;
  }
}

@media (width >= 1024px) {
  .m-button {
    cursor: none;
    height: 55px;
    padding: 0 24px;
  }
}

.m-button.regular {
  transition: all .3s;
}

.m-button.regular:hover {
  background-color: #0000;
  border: 1px solid #9dc08b;
}

.m-button.right {
  margin: 0;
}

.m-button.none {
  border: none;
  border-radius: 100%;
  width: 150px;
  height: 150px;
  margin: auto;
  padding: 0;
}

@media (width >= 1024px) {
  .m-button.none {
    width: 200px;
    height: 200px;
  }
}

.m-button.none:hover {
  color: #fff;
  background-color: #0000;
}

.m-button .round {
  text-align: center;
  cursor: pointer;
  border: 1px solid #0000;
  border-radius: 200px;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  margin: auto;
  font-size: clamp(12px, .130719vw + 11.4902px, 14px);
  transition: all .3s;
  display: flex;
}

@media (width >= 1024px) {
  .m-button .round {
    cursor: none;
    width: 200px;
    height: 200px;
  }
}

.m-button .round:hover {
  border: 1px solid #9dc08b;
}

.m-button > span {
  z-index: 2;
  font-size: clamp(14px, .261438vw + 12.9804px, 18px);
  transition: transform .15s linear;
  display: inline-block;
  position: relative;
}

.general-wrapper {
  max-width: 800px;
  margin: auto;
  padding: 0 16px;
  font-size: clamp(14px, .261438vw + 12.9804px, 18px);
}

@media (width >= 850px) {
  .general-wrapper {
    padding: 0 16px;
  }
}

@media (width >= 1024px) {
  .general-wrapper {
    padding: 0 8vw;
    font-size: clamp(17px, .522876vw + 14.9608px, 25px);
  }
}

@media (width >= 1920px) {
  .general-wrapper {
    max-width: 1100px;
  }
}

.simple-footer-wrapper {
  text-align: center;
  align-content: center;
  align-items: center;
  height: 80vh;
  min-height: 600px;
  display: grid;
}

.simple-footer-wrapper.dark a {
  color: #fff !important;
}

.scroll-down {
  width: 34px;
  height: 55px;
  margin: auto auto 24px;
  position: absolute;
  inset: 0;
}

.scroller {
  background-color: #5b6f51;
  border-radius: 5px;
  width: 1px;
  height: 50px;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15, .41, .69, .94);
  animation-iteration-count: infinite;
}

@keyframes scroll {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 1;
    transform: translateY(-10px);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes RevealThings {
  0% {
    opacity: 0;
    transform-style: preserve-3d;
    transform: translate3d(0, 40px, 0)scale3d(1, 1, 1)rotateX(0)rotateY(0)rotateZ(0)skew(0deg, 3deg);
  }

  100% {
    opacity: 1;
    transform-style: preserve-3d;
    opacity: 1;
    transform: translate3d(0, 0, 0)scale3d(1, 1, 1)rotateX(0)rotateY(0)rotateZ(0)skew(0deg);
  }
}

@keyframes RevealThingsSlight {
  0% {
    opacity: 0;
    transform-style: preserve-3d;
    transform: translate3d(0, 40px, 0)scale3d(1, 1, 1)rotateX(0)rotateY(0)rotateZ(0)skew(0deg, 3deg);
  }

  100% {
    opacity: 1;
    transform-style: preserve-3d;
    opacity: .4;
    transform: translate3d(0, 0, 0)scale3d(1, 1, 1)rotateX(0)rotateY(0)rotateZ(0)skew(0deg);
  }
}

@keyframes reveal-fx {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes secondaryImageOverlayIn {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes secondaryImageOverlayOut {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes fadeIn-slight {
  0% {
    opacity: 0;
  }

  100% {
    opacity: .4;
  }
}

@keyframes animateHeart {
  0% {
    transform: scale(.92);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(.92);
  }

  60% {
    transform: scale(1);
  }

  80% {
    transform: scale(.92);
  }

  100% {
    transform: scale(.92);
  }
}

.center {
  text-align: center;
  display: block;
}

.fullwidth {
  width: 100%;
}

#particles-js {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.shape-wrap {
  opacity: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  animation: 1s 1.2s forwards fadeIn;
  display: flex;
  position: absolute;
}

.shape-wrap .shape {
  background: linear-gradient(64.0092deg, #202620 23.4414% 23.4414%, #181d18 79.8477%);
  width: 250px;
  height: 350px;
  animation: 4s ease-in-out infinite wave;
}

@media (width >= 1024px) {
  .shape-wrap .shape {
    width: 400px;
    height: 400px;
  }
}

@keyframes wave {
  0%, 100% {
    border-radius: 70% 30% 72% 28% / 27% 61% 39% 73%;
  }

  50% {
    border-radius: 77% 23% 54% 46% / 40% 38% 62% 60%;
  }
}

@keyframes logohover {
  22.2% {
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}
/*# sourceMappingURL=index.6463d3ec.css.map */
