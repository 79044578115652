/*********************
H-tags
*********************/

h1 {
   font-size: $text-xxl;
   font-weight: $font-weight-regular;
   margin: 10px 0;
   font-family: $font-family-heading;
 
   @include breakpoint(lg) {
     margin: 20px 0;
   }
 }

 h2 {
   font-size: $text-xl;
   
   font-weight: $font-weight-regular;
   margin: 10px 0;
   font-family: $font-family-heading;
 
   @include breakpoint(lg) {
     margin: 20px 0;
 
   }
 }

 h3 {
  font-size: $text-lg;
   margin: 10px 0;
   font-family: $font-family-heading;
 
   @include breakpoint(lg) {
     margin: 20px 0;
 
   }
 }
 
 h4 {
   font-size: $text-md;
   font-weight: $font-weight-light;
   margin: 10px 0;
 
 
   @include breakpoint(lg) {
     margin: 20px 0;
   }
   @include breakpoint(xl) {

     margin: 20px 0;
   }
 }


.muted {
  opacity: .6;
}
.small {
  font-size: $text-sm;
}
.xsmall {
  font-size: $text-xs;
}
.nomargin {
  margin: 0;
}